import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import B2BCourseTemplateModel from '@uz/unitz-models/B2BCourseTemplateModel';
import useRoute from '@vl/hooks/useGbRouteDe';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),
            filterForm: hook((ctx) => {
              const route = useRoute();
              const locale = _.get(route.getPageContext(), 'lang', '');
              const routeParams = route.getParams();
              const account_slug = _.get(routeParams, 'slug');
              const search_data = _.get(routeParams, 'search', '');
              const form = useFormik({
                initialValues: {
                  search: search_data,
                },

                onSubmit: async (values, actions) => {
                  try {
                    const filterPagingModel = ctx.apply('REF.getRef', 'CourseTemplatePagingModel');
                    if (filterPagingModel) {
                      await filterPagingModel.onLoadPage(0);
                    }
                    route.navigate(
                      route.to(
                        !!account_slug
                          ? `/${locale}/${account_slug}/course-templates`
                          : `/${locale}/accounts/me/course-template`,
                        {
                          ..._.omit(form.values, ['']),
                        }
                      )
                    );
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },

                validationSchema: Yup.object().shape({}),
              });

              return form;
            }),

            getFilterArgs: hook((ctx) => {
              return () => {
                const values = ctx.get('filterForm.values', {});
                const args = {
                  search: _.get(values, 'search', ''),
                };
                return args;
              };
            }),

            paginationModel: hook((ctx) => {
              const ref = React.useRef({});
              const account_id = ctx.apply('accountModel.getAccountId');
              _.assign(ref.current, { getFilterArgs: ctx.get('getFilterArgs') });

              const pagingModel = React.useMemo(() => {
                const condition = `where: { account_id: {_eq: "${account_id}" }}`;
                const dataQuery = `
                  b2b_search_course_template (args: $args, ${condition}, order_by: {created_at: desc_nulls_last}){
                    id
                    user_id
                    name
                    account_id
                    description
                    link_urls
                    materials
                    photo_url
                    course_template_lessons {
                      user_id
                      account_id
                      name
                      description
                      link_urls
                      materials
                    }
                    courses (where: {status: {_in: [draft, ongoing]}}, order_by: {start_at: asc}, limit: 4) {
                      id
                      start_at
                    }
                  }
                `;
                const pagingModel = PaginationModel.fromConfig({
                  Model: B2BCourseTemplateModel,
                  dataQuery,
                  aggQuery: `
                    b2b_search_course_template_aggregate(args: $args, ${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 10,
                  varsList: '$args: b2b_search_course_template_args!',
                  getVars: () => {
                    return {
                      args: ref.current.getFilterArgs(),
                    };
                  },
                });
                return pagingModel;
              }, []);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                hasFilterArgs: () => !!_.compact(_.values(ref.current.getFilterArgs())).length,
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };
              ctx.apply('REF.setRef', 'CourseTemplatePagingModel', pagination);
              return pagination;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
